
import {uaCheck} from './modules/utils';

uaCheck();

const mq = (device) => {
  if (window.matchMedia('(max-width:768px)').matches && device === 'sp') {
    return true;
  }
  return window.matchMedia('(min-width:769px)').matches && device === 'pc';
};


// Menu UI Functions
const menuToggleFunc = () => {
  const mTrigger = document.getElementById('menu-trigger');
  mTrigger.addEventListener('click', () => {
    if ($('body').hasClass('menu-show')) {
      $('body').removeClass('menu-show');
    } else {
      $('body').addClass('menu-show');
    }
  });
};



window.addEventListener('DOMContentLoaded', () => {
  menuToggleFunc();

  goToPageTop('.js-page-top');
  if(document.getElementById("categoryLinks") !== null) {
    $(window).on('scroll', _.throttle(handleCategoryLink, 200));
  }
});
